import { atom } from 'recoil';

export const menuListAtom = atom({
  key: 'menuListAtom',
  default: {
    allMenuList: JSON.parse(localStorage.getItem('menu_list')) ?? [],
    commonMenuList: JSON.parse(localStorage.getItem('commonmenu_list')) ?? [],
    commonMenuListClone: JSON.parse(localStorage.getItem('commonmenu_list')) ?? [],
  },

  // default: JSON.parse(localStorage.getItem('menu_list')) ?? [],
});
